import { ampli } from "~/ampli/index";
var loadPromise = ampli.load({
  environment: "estaid",
  client: {
    apiKey: process.env.amplitudeKey,
    configuration: {
      defaultTracking: false,
      serverUrl: process.env.amplitudeEndpoint
    }
  }
}).promise;
export default (function (context, inject) {
  inject("amplitude", ampli);
});
export { ampli, loadPromise };