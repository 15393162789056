import { state as defaultState } from "./explore.js";
import Explore from "../graphql/Explore/Explore.gql";

export const state = () => ({
  hasSearchedYet: false,
  hasClearedFilters: false,
  loading: false,
  geography: [],
  ownership: [],
  cvr: [],
  subcompanies: [],
  includeSubcompanies: true,
  polygon: [],
  circle: {
    radius: null,
    center: null,
  },
  usage: [],
  size: {
    emptyPlot: false,
    siteArea: [0, 30000],
    buildingArea: [0, 30000],
    buildingAreaPct: [0, 300],
    noOfFloors: [0, 6],
    noOfUnits: [0, 50],
  },

  construction: [1700, 2021],
  price: [0, 500000000],
  result: {},
  limit: 25,
  offset: 0,
  polygonOrCircleUpdated: false,
});
export const actions = {
  removeFilters(store) {
    store.commit("clearOffset");
    store.commit("clearLimit");
    store.commit("clearGeography");
    store.commit("clearCvr");
    store.commit("clearPolygon");
    store.commit("clearCircle");
    store.commit("clearUsage");
    store.commit("clearOwnership");
    store.commit("clearSize");
    store.commit("clearSizeEmptyPlot");
    store.commit("clearSizeSiteArea");
    store.commit("clearSizeBuildingArea");
    store.commit("clearSizeBuildingAreaPct");
    store.commit("clearSizeNoOfFloors");
    store.commit("clearSizeNoOfUnits");
    store.commit("clearPrice");
    store.commit("clearConstruction");
    store.commit("clearResult");
    store.commit("clearIncludeSubcompanies");
    store.commit("clearHasSearchedYet");
    store.commit("clearLoading");
  },
};
export const mutations = {
  search() {
    this.app.store.commit("explore/setLoadingState", true);
    this.app.store.commit("explore/setPolygonOrCircleUpdated", false);
    let filter = {
      parameters: this.app.store.getters["explore/getQueryArray"],
      limit: this.app.store.getters["explore/getLimit"],
      offset: this.app.store.getters["explore/getOffset"],
    };
    this.app.apolloProvider.defaultClient
      .query({
        query: Explore,
        variables: { filter: filter },
        fetchPolicy: "network-only",
      })
      .then((resp) => {
        this.app.store.commit("explore/addResult", resp.data.explore);
        this.app.store.commit("explore/setLoadingState", false);
        this.app.amplitudeLogExploreEvent();
      })
      .catch(() => {
        this.app.store.commit("explore/setLoadingState", false);
      });
  },
  RESET_STATE(state) {
    let copyOfDefault = defaultState();
    copyOfDefault.hasClearedFilters = true;
    delete copyOfDefault.loading;
    delete copyOfDefault.hasSearchedYet;
    Object.assign(state, copyOfDefault);
  },
  //ADDERS AND REMOVERS
  addResult(state, change) {
    state.result = change;
  },
  removeGeography(state, change) {
    const index = state.geography.indexOf(change);
    if (index > -1) {
      state.geography.splice(index, 1);
    }
  },
  addCvr(state, change) {
    if (!state.cvr.some((x) => x.cvr === change.cvr)) {
      state.cvr.push(change);
    }
  },
  removeCvr(state, change) {
    const index = state.cvr.indexOf(change);
    if (index > -1) {
      state.cvr.splice(index, 1);
    }
    if (change.subcompanies > 0) {
      state.subcompanies = state.subcompanies.filter((x) => x.parent != change.cvr);
    }
  },
  removeSubcompanies(state, change) {
    const index = state.subcompanies.indexOf(change);
    if (index > -1) {
      state.subcompanies.splice(index, 1);
      let parent = state.cvr.find((x) => x.cvr == change.parent);
      parent.subcompanies = parent.subcompanies - 1;
    }
  },
  removeUsage(state, change) {
    const index = state.usage.indexOf(change);
    if (index > -1) {
      state.usage.splice(index, 1);
    }
  },
  addUsage(state, change) {
    const index = state.usage.indexOf(change);
    if (index == -1) {
      state.usage.push(change);
    } else if (index > -1) {
      state.usage.splice(index, 1);
    }
  },
  removeOwnership(state, change) {
    const index = state.ownership.indexOf(change);
    if (index > -1) {
      state.ownership.splice(index, 1);
    }
  },
  setOffset(state, change) {
    state.offset = change;
  },
  setLimit(state, change) {
    state.limit = change;
  },

  //SETTERS
  setGeography(state, change) {
    state.polygon = [];
    state.circle = { radius: null, center: null };
    state.geography = change;
  },
  setPolygon(state, change) {
    state.geography = [];
    state.polygon = change;
  },
  setCircle(state, change) {
    state.geography = [];
    state.circle = change;
  },
  setConstruction(state, change) {
    state.construction = change;
  },
  setUsage(state, change) {
    state.usage = change;
  },
  setOwnership(state, change) {
    state.ownership = change;
  },
  setPrice(state, change) {
    state.price = change;
  },
  setSizeEmptyPlot(state, change) {
    state.size.emptyPlot = change;
  },
  setSizeSizeSiteArea(state, change) {
    state.size.siteArea = change;
  },
  setSizeBuildingArea(state, change) {
    state.size.buildingArea = change;
  },
  setSizeBuildingAreaPct(state, change) {
    state.size.buildingAreaPct = change;
  },
  setSizeNoOfFloors(state, change) {
    state.size.noOfFloors = change;
  },
  setSizeNoOfUnits(state, change) {
    state.size.noOfUnits = change;
  },
  setLoadingState(state, change) {
    state.loading = change;
  },
  setIncludeSubcompanies(state, change) {
    state.includeSubcompanies = change;
  },
  setSubcompanies(state, change) {
    state.subcompanies = state.subcompanies.concat(change);
  },
  setHasSearchedYet(state, change) {
    state.hasSearchedYet = change;
  },
  //CLEARERS

  clearOffset(state) {
    state.offset = defaultState().offset;
  },
  clearLimit(state) {
    state.offset = defaultState().offset;
  },
  clearGeography(state) {
    state.geography = defaultState().geography;
  },
  clearCvr(state) {
    state.cvr = defaultState().cvr;
  },
  clearPolygon(state) {
    state.polygon = [];
  },
  clearCircle(state) {
    state.circle = {
      radius: null,
      center: null,
    };
  },
  clearUsage(state) {
    state.usage = defaultState().usage;
  },
  clearOwnership(state) {
    state.ownership = defaultState().ownership;
  },
  clearSize(state) {
    state.size = defaultState().size;
  },
  clearSizeEmptyPlot(state) {
    state.size.emptyPlot = defaultState().size.emptyPlot;
  },
  clearSizeSiteArea(state) {
    state.size.siteArea = defaultState().size.siteArea;
  },
  clearSizeBuildingArea(state) {
    state.size.buildingArea = defaultState().size.buildingArea;
  },
  clearSizeBuildingAreaPct(state) {
    state.size.buildingAreaPct = defaultState().size.buildingAreaPct;
  },
  clearSizeNoOfFloors(state) {
    state.size.noOfFloors = defaultState().size.noOfFloors;
  },
  clearSizeNoOfUnits(state) {
    state.size.noOfUnits = defaultState().size.noOfUnits;
  },
  clearPrice(state) {
    state.price = defaultState().price;
  },
  clearConstruction(state) {
    state.construction = defaultState().construction;
  },
  clearResult(state) {
    state.result = defaultState().result;
  },
  clearIncludeSubcompanies(state) {
    state.includeSubcompanies = false;
  },
  clearHasSearchedYet(state) {
    state.hasSearchedYet = false;
  },
  clearLoading(state) {
    state.loading = false;
  },
  clearSubcompanies(state) {
    state.subcompanies = [];
  },
  setPolygonOrCircleUpdated(state, value) {
    state.polygonOrCircleUpdated = value;
  },
};
export const getters = {
  //STATEGETTERS
  getDefault: (state) => (param) => state[param.charAt(0).toLowerCase() + param.slice(1)],
  getDefaults() {
    return defaultState();
  },
  getState(state) {
    return state;
  },
  getPolygonOrCircleUpdated(state) {
    return state.polygonOrCircleUpdated;
  },
  getHasClearedFilters(state) {
    return state.hasClearedFilters;
  },
  getSizeEmptyPlot(state) {
    return state.size.emptyPlot;
  },
  getSizeSiteArea(state) {
    return state.size.siteArea;
  },
  getSizeBuildingArea(state) {
    return state.size.buildingArea;
  },
  getSizeNoOfFloors(state) {
    return state.size.noOfFloors;
  },
  getSizeNoOfUnits(state) {
    return state.size.noOfUnits;
  },
  getGeography(state) {
    return state.geography;
  },
  getOwnership(state) {
    return state.ownership;
  },
  getCvr(state) {
    return state.cvr;
  },
  getConstruction(state) {
    return state.construction;
  },
  getUsage(state) {
    return state.usage;
  },
  getPrice(state) {
    return state.price;
  },
  getPolygon(state) {
    return state.polygon;
  },
  getCircle(state) {
    return state.circle;
  },
  getOffset(state) {
    return state.offset;
  },
  getLimit(state) {
    return state.limit;
  },
  getHasSearchedYet(state) {
    return state.hasSearchedYet;
  },
  getIncludeSubcompanies(state) {
    return state.includeSubcompanies;
  },
  getSubcompanies(state) {
    return state.subcompanies;
  },

  //ENABLED GETTERS
  getGeographyEnabled(state) {
    return state.geography.length > 0;
  },
  getPolygonEnabled(state) {
    return state.polygon.length > 0;
  },
  getCircleEnabled(state) {
    return state.circle.radius && state.circle.center;
  },
  getOwnershipEnabled(state) {
    return state.ownership.length > 0;
  },
  getCvrEnabled(state) {
    return state.cvr.length > 0;
  },
  getDrawingEnabled(state) {
    return state.polygon.length > 0 || (state.circle.radius && state.circle.center);
  },
  getUsageEnabled(state) {
    return state.usage.length > 0;
  },
  getSizeEmptyPlotEnabled(state) {
    return state.size.emptyPlot;
  },
  getSizeSiteAreaEnabled(state) {
    return !checkArrays(state.size.siteArea, defaultState().size.siteArea);
  },
  getSizeBuildingAreaEnabled(state) {
    return !checkArrays(state.size.buildingArea, defaultState().size.buildingArea);
  },
  getSizeBuildingAreaPctEnabled(state) {
    return !checkArrays(state.size.buildingAreaPct, defaultState().size.buildingAreaPct);
  },
  getSizeNoOfFloorsEnabled(state) {
    return !checkArrays(state.size.noOfFloors, defaultState().size.noOfFloors);
  },
  getSizeNoOfUnitsEnabled(state) {
    return !checkArrays(state.size.noOfUnits, defaultState().size.noOfUnits);
  },
  getSizeEnabled(state, getters) {
    return (
      getters.getSizeSiteAreaEnabled ||
      getters.getSizeEmptyPlotEnabled ||
      getters.getSizeBuildingAreaEnabled ||
      getters.getSizeBuildingAreaPctEnabled ||
      getters.getSizeNoOfFloorsEnabled ||
      getters.getSizeNoOfUnitsEnabled
    );
  },
  getConstructionEnabled(state) {
    return !checkArrays(state.construction, defaultState().construction);
  },
  getPriceEnabled(state) {
    return !checkArrays(state.price, defaultState().price);
  },

  getNumberOfAppliedFilters(state, getters) {
    let counter = 0;
    if (getters.getGeographyEnabled) counter += getters.getGeography.length;
    if (getters.getDrawingEnabled) counter++;
    if (getters.getOwnershipEnabled) counter++;
    if (getters.getCvrEnabled) counter++;
    if (getters.getUsageEnabled) counter += getters.getUsage.length;
    if (getters.getSizeSiteAreaEnabled) counter++;
    if (getters.getSizeBuildingAreaEnabled) counter++;
    if (getters.getSizeBuildingAreaPctEnabled) counter++;
    if (getters.getSizeNoOfFloorsEnabled) counter++;
    if (getters.getSizeNoOfUnitsEnabled) counter++;
    if (getters.getConstructionEnabled) counter++;
    if (getters.getPriceEnabled) counter++;

    return counter;
  },
  getResult(state) {
    return state.result;
  },
  getLoadingState(state) {
    return state.loading;
  },

  getQueryArray(state, getters) {
    let query = [];

    class QueryBlock {
      constructor(id, values, min, max) {
        this.id = id;
        this.values = values;
        this.min = min;
        this.max = max;
      }
    }

    if (getters.getDrawingEnabled) {
      if (state.polygon.length > 0) {
        let polygonString = "POLYGON((";
        state.polygon[0].forEach((coordinate) => {
          polygonString += coordinate[0].toString() + " " + coordinate[1].toString() + ",";
        });
        polygonString = polygonString.substring(0, polygonString.length - 1);
        polygonString += "))";
        query.push(new QueryBlock("polygon", [polygonString], null, null));
      } else {
        query.push(new QueryBlock("circle", [state.circle.center[1].toString(), state.circle.center[0].toString()], null, parseFloat(state.circle.radius)));
      }
    }
    if (getters.getGeographyEnabled) {
      let arr = [];

      state.geography.forEach((x) => {
        arr.push("0" + x.id.toString());
      });
      query.push(new QueryBlock("geography", arr, null, null));
    }
    if (getters.getOwnershipEnabled) {
      let arr = [];

      state.ownership.forEach((x) => {
        arr.push(x.id.toString());
      });
      query.push(new QueryBlock("ownership", arr, null, null));
    }
    if (getters.getCvrEnabled) {
      let arr = [];

      state.cvr.forEach((x) => {
        arr.push(x.cvr.toString());
      });
      state.subcompanies.forEach((x) => {
        arr.push(x.cvr.toString());
      });
      query.push(new QueryBlock("cvr", arr, null, null));
    }

    if (getters.getUsageEnabled) {
      let arr = [];
      state.usage.forEach((x) => {
        arr.push(x.values.toString());
      });
      query.push(new QueryBlock("usage", arr.flat(), null, null));
    }
    if (getters.getSizeEnabled) {
      if (getters.getSizeEmptyPlot) {
        query.push(new QueryBlock("emptyPlot", null, null, null));
      } else {
        !getters.getSizeSiteAreaEnabled
          ? null
          : query.push(
              new QueryBlock(
                "siteArea",
                null,
                state.size.siteArea[0] == defaultState().size.siteArea[0] ? null : parseFloat(state.size.siteArea[0]),
                state.size.siteArea[1] == defaultState().size.siteArea[1] ? null : parseFloat(state.size.siteArea[1])
              )
            );
        !getters.getSizeBuildingAreaEnabled
          ? null
          : query.push(
              new QueryBlock(
                "buildingArea",
                null,
                state.size.buildingArea[0] == defaultState().size.buildingArea[0] ? null : parseFloat(state.size.buildingArea[0]),
                state.size.buildingArea[1] == defaultState().size.buildingArea[1] ? null : parseFloat(state.size.buildingArea[1])
              )
            );
        !getters.getSizeBuildingAreaPctEnabled
          ? null
          : query.push(
              new QueryBlock(
                "buildingAreaPct",
                null,
                state.size.buildingAreaPct[0] == defaultState().size.buildingAreaPct[0] ? null : parseFloat(state.size.buildingAreaPct[0]),
                state.size.buildingAreaPct[1] == defaultState().size.buildingAreaPct[1] ? null : parseFloat(state.size.buildingAreaPct[1])
              )
            );
        !getters.getSizeNoOfFloorsEnabled
          ? null
          : query.push(
              new QueryBlock(
                "noOfFloors",
                null,
                state.size.noOfFloors[0] == defaultState().size.noOfFloors[0] ? null : parseFloat(state.size.noOfFloors[0]),
                state.size.noOfFloors[1] == defaultState().size.noOfFloors[1] ? null : parseFloat(state.size.noOfFloors[1])
              )
            );
        !getters.getSizeNoOfUnitsEnabled
          ? null
          : query.push(
              new QueryBlock(
                "noOfUnits",
                null,
                state.size.noOfUnits[0] == defaultState().size.noOfUnits[0] ? null : parseFloat(state.size.noOfUnits[0]),
                state.size.noOfUnits[1] == defaultState().size.noOfUnits[1] ? null : parseFloat(state.size.noOfUnits[1])
              )
            );
      }
    }
    if (getters.getConstructionEnabled) {
      query.push(
        new QueryBlock(
          "construction",
          null,
          state.construction[0] == defaultState().construction[0] ? null : parseFloat(state.construction[0]),
          state.construction[1] == defaultState().construction[1] ? null : parseFloat(state.construction[1])
        )
      );
    }
    if (getters.getPriceEnabled) {
      query.push(
        new QueryBlock(
          "price",
          null,
          state.price[0] == defaultState().price[0] ? null : parseFloat(state.price[0]),
          state.price[1] == defaultState().price[1] ? null : parseFloat(state.price[1])
        )
      );
    }
    return query;
  },
};
function checkArrays(arr1, arr2) {
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
